import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrganisationLeave } from "redux/appThunk/Employee/leaves";
import { AddButton } from "component/common/accessControlUi/Button";
import CustomTable from "component/common/table/CustomTable";
import { IoFilter } from "react-icons/io5";
import { FaEye } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import AttendanceFilter from "../employee/attendance/AttendanceFilter";
import AddLeave from "./AddLeaveData";
import Paginate from "component/admin/Employee/Paginate";
// import usePermissions, { mappedPermissionObj } from 'hooks/usePermission';// permission related comments TODO hemraj
import Search from "component/common/accessControlUi/Search";
import PropTypes from "prop-types";

const OrganisationLeaveTable = ({
  handleFilterApply,
  handleResetFilters,
  handleViewDetails,
  columns,
  handleDepartmentChange,
  handleDesignationChange,
  localSelectedDepartment,
  localSelectedDesignation,
  dataFilter,
  dataFilterValue,
  searchItem,
  searchName,
  showDetails,
  setSearch,
  setFilter,
  filter,
  addLeavePop,
  setAddLeavePop,
}) => {
  const { t } = useTranslation();
  // const permisionsLeaveType = usePermissions(mappedPermissionObj.Leavetype);
  // const secondPermission = permisionsLeaveType.userPermission.viewAll;
  // const { userPermission } = usePermissions(mappedPermissionObj.Leave);

  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const { Organisation, pageCount, isLoading } = useSelector(
    (state) => state.leaveReducer
  );

  useEffect(() => {
    dispatch(fetchOrganisationLeave(currentPage, itemsPerPage, "", "", searchItem));
  }, [dispatch, currentPage, itemsPerPage, searchName,showDetails]);

  return (
    <>
      <div className="w-full h-fit overflow-hidden">
        <div
          className="w-[97.5%] h-[calc(100vh-10rem)] bg-white space-y-4
        flex flex-col p-5 pb-1 rounded-xl shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] mb-3 ml-3 mt-4"
        >
          <div className="w-full h-16 bg-white flex justify-between">
            <div className="flex justify-center items-center">
              <h2 className="font-extrabold text-xl text-[#002169]">
                {t("details")}
              </h2>
            </div>
            <div className="flex items-center justify-center gap-4">
              <Search
                searchItem={searchItem}
                setSearchItem={setSearch}
                //  currentResource={userPermission}
              />
              <div className="relative">
                <div className="p-3 text-xl rounded-full border"
                  data-testId='filter-icon'>
                  <IoFilter
                    className="w-6 h-6 stroke-[#031B59]"
                    onClick={() => setFilter(!filter)}
                  />
                </div>
                {filter && (
                  <div className="absolute top-[3.6rem] right-[3rem] z-50">
                    <AttendanceFilter
                      showFilter={filter}
                      setFilter={setFilter}
                      setShowFilter={setFilter}
                      dataFilter={dataFilter}
                      dataFilterValue={dataFilterValue}
                      filterTitleDepartMent={t("department")}
                      filterTitleDesignation={t("designation")}
                      localSelectedDepartment = {localSelectedDepartment}
                      localSelectedDesignation = {localSelectedDesignation}
                      handleDepartmentChange = {handleDepartmentChange}
                      handleDesignationChange = {handleDesignationChange}
                      handleApply = {handleFilterApply}
                      handleRefresh = {handleResetFilters}
                    />
                  </div>
                )}
              </div>
              <AddButton
                // currentResource={mappedPermissionObj.Leave}
                title={t("Apply Leave")}
                onClick={() => setAddLeavePop(true)}
              />
              {addLeavePop && <AddLeave setAddLeavePop={setAddLeavePop} />}
            </div>
          </div>
          <div
            className={`overflow-x-scroll w-full ${isLoading ? "custom_scroller" : "custom_scroll"}`}
          >
            <CustomTable
              columns={columns}
              data={Organisation}
              isLoading={isLoading}
              renderAction={(value) => (
                <FaEye onClick={() => handleViewDetails(value)} />
              )}
            />
          </div>

          {/* {(userPermission?.view || secondPermission) && ( */}
          <div className="w-full h-12 bg-white flex justify-between items-center">
            {!isLoading && (
              <>
                <div className="w-full flex flex-wrap justify-between items-center mb-4 ">
                  <div className="text-[#031B59] bg-white font-medium ">
                    {t("Showing")} {currentPage} {t("of")} {pageCount}
                  </div>
                  <Paginate
                    currentPage={currentPage}
                    initialPageCount={pageCount}
                    pageRangeDisplayed={5}
                    next=">"
                    previous="<"
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              </>
            )}
            {Organisation.length === 0 && (
              <div className="w-full flex items-center justify-center font-medium">
                {t("no_data_found")}
              </div>
            )}
          </div>
          {/* )} */}
        </div>
      </div>
    </>
  );
};

export default OrganisationLeaveTable;

OrganisationLeaveTable.propTypes = {
  handleFilterApply: PropTypes.func,
  handleViewDetails: PropTypes.func,
  handleResetFilters: PropTypes.func,
  columns: PropTypes.array,
  dataFilter: PropTypes.array,
  dataFilterValue: PropTypes.array,
  searchItem: PropTypes.any,
  searchName: PropTypes.any,
  showDetails: PropTypes.any,
  setSearch: PropTypes.func,
  setFilter: PropTypes.func,
  filter: PropTypes.bool,
  addLeavePop: PropTypes.any,
  setAddLeavePop: PropTypes.func,
  handleDepartmentChange: PropTypes.func,
  handleDesignationChange: PropTypes.func,
  localSelectedDepartment: PropTypes.array,
  localSelectedDesignation: PropTypes.array,
};
