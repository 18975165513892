import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrganisationLeave } from "redux/appThunk/Employee/leaves";
import ProfileAvtar from "component/common/ProfileAvtar";
// import usePermissions, { mappedPermissionObj } from 'hooks/usePermission';// permission related comments TODO hemraj
import useDebounce from "hooks/useDebounce";
import {
  handleLeaveSearchData,
  handleStatusChange
} from "redux/appThunk/Admin/leave";
import MyLeavepopup from "./MyLeavepopup";
import EditLeaveDetails from "./EditLeaveDetails";
import Cancelpopup from "./Cancelpopup";
import PropTypes from "prop-types";
import OrganisationleaveDetails from "./OrganisationleaveDetails";
import OrganisationLeaveTable from "./OrganisationLeaveTable";
import { alignValues } from "./column";
import StatusPopup from "./StatusPopup";
import { FaEye } from "react-icons/fa";
import { fetchDepartments } from "redux/appThunk/Admin/department";

const columns = [
  {
    title: "Employee Name",
    field: "name",
    align: "left",
    className: "pl-2",
    render: (value, job) => (
      <div className="flex">
        <div className="w-12 h-12 m-3">
          {job?.employeePicture ? (
            <img
              className="w-full h-full object-cover flex items-center border-2 border-white rounded-full"
              src={job?.employeePicture}
              alt="Profile Avatar"
            />
          ) : (
            <ProfileAvtar
              className="h-[3rem] width-[3rem]"
              name={job?.name ? job?.name : null}
            />
          )}
        </div>
        <div className="pl-2 py-5 flex flex-col text-left justify-center">
          <div>{job?.name}</div>
          <div className="text-[14px] text-[#A1A1A1]">{job?.empCode}</div>
        </div>
      </div>
    ),
  },
  {
    title: "Department",
    field: "department",
    align: "left",
    className: "pl-4",
  },
  {
    title: "Designation",
    field: "designation",
    align: "center",
    className: "pl-4",
  },
];

const OrganisationLeave = ({activeTab}) => {
  // const permisionsLeaveType = usePermissions(mappedPermissionObj.Leavetype);
  // const secondPermission = permisionsLeaveType.userPermission.viewAll;
  // const { userPermission } = usePermissions(mappedPermissionObj.Leave);

  const dispatch = useDispatch();
  const [filter, setFilter] = useState(false);
  const [addLeavePop, setAddLeavePop] = useState(false);
  const [currentPage, setCurrentPage] = useState("1");
  const [searchItem, setSearch] = useState("");
  const itemsPerPage = 10;
  const searchName = useDebounce(searchItem, 800);
  const [showDetails, setShowDetails] = useState(false);
  const [userIdAction, setUserIdAction] = useState(null);
  const [datas, setDatas] = useState(null);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [openPopup, setopenPopup] = useState(false);
  const [handlecancelpopup, setHandlecancelpopup] = useState(false);
  const [checkedDesignation, setCheckedDesignation] = useState(new Set());
  const [checkedDepartment, setCheckedDepartment] = useState(new Set());
  const { data } = useSelector((state) => state.departmentReducer);
  const [ids, setIds] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [popupBtn, setPopupBtn] = useState("");
  const [dataFilter, setDataFilter] = useState([]);
  const [dataFilterValue, setDataFilterValue] = useState([]);
  const [departmentId, setDepartmentId] = useState(null);
  const [localSelectedDepartment, setLocalSelectedDepartment] = useState([]);
  const [localSelectedDesignation, setLocalSelectedDesignation] = useState([]);
  const [localSelectedDepartmentTitle, setLocalSelectedDepartmentTitle] = useState([]);
  const [localSelectedDesignationTitle, setLocalSelectedDesignationTitle] = useState([]);

  useEffect(() => {
    const dataFilter = data?.data?.map((item) => ({
      id: item?.id,
      title: item?.department,
    }));
    setDataFilter(dataFilter);
  }, [data]);

  useEffect(() => {
    const filterData = data?.data?.find((item) => item?.id === departmentId);
    const dataFilterValue = filterData?.designation?.map((item) => ({
      id: item?.id,
      title: item?.designation,
    }));
    setDataFilterValue(dataFilterValue);
  }, [departmentId]);

  useEffect(() => {
    dispatch(fetchDepartments(1, 10, "", "",""));
  }, [dispatch,filter]);

  const columnData = [
    {
      title: "Status ",
      field: "status",
      align: alignValues.left,
      render: (index, data) => {
        return (
          <>
            <div className="items-start">
              <StatusPopup
                key={index}
                leave={data}
                handleOpenDropDown={handleOpenDropDown}
                handleSelect={handleOptionClick}
                showDropDown={data?.id === ids}
                handleOpen={handleOpens}
                setPopupTitle={setPopupTitle}
                setPopupBtn={setPopupBtn}
                activeTab={activeTab}
                data-testId='status-popup'
              />
            </div>
          </>
        );
      },
      className: "min-w-[151px] px-[15px] h-[50px] text-[#686868]",
      style: "",
    },
    {
      title: "Action ",
      field: "action",
      align: alignValues.left,
      render: (index, data) => {
        return (
          <>
            <div className="items-start">
              <div onClick={() => handleOpen(data)}>
                <FaEye/>
              </div>
            </div>
          </>
        );
      },
      className: "min-w-[151px] px-[15px] h-[50px] text-[#686868]",
      style: "",
    },
  ];

  const handleFilterApply = () => {
    dispatch(fetchOrganisationLeave("","",[...localSelectedDepartmentTitle], [...localSelectedDesignationTitle],""));
    setFilter(false);
  };

  const handleResetFilters = () => {
    setLocalSelectedDepartment([]);
    setLocalSelectedDepartmentTitle([]);
    setLocalSelectedDesignationTitle([]);
    setLocalSelectedDesignation([]);
    setFilter(false);
    setCheckedDepartment(new Set());
    setCheckedDesignation(new Set());
    setFilter(false);
    dispatch(fetchOrganisationLeave(currentPage, itemsPerPage, "", "", searchItem));
  };

  const handleDepartmentChange = (resource) => {
    setDepartmentId(resource?.id);
    setLocalSelectedDepartment((prev) =>
      prev.includes(resource.id) ? prev.filter(item => item !== resource.id) : [...prev, resource.id]
    );
    setLocalSelectedDepartmentTitle((prev) =>
      prev.includes(resource.title) ? prev.filter(item => item !== resource.title) : [...prev, resource.title]
    );
  };

  const handleDesignationChange = (project) => {
    setLocalSelectedDesignation((prev) =>
      prev.includes(project.id) ? prev.filter(item => item !== project?.id) : [...prev, project?.id]
    );
    setLocalSelectedDesignationTitle((prev) =>
      prev.includes(project.title) ? prev.filter(item => item !== project?.title) : [...prev, project?.title]
    );
  };

  const handleViewDetails = (data) => {
    setDatas(data);
    setShowDetails(true);
    setUserIdAction(data.userId);
    dispatch(handleLeaveSearchData({ userId: data?.userId }));
  };

  const handleOpenDropDown = (id) => {
    setIds(id);
  };

  const handleOptionClick = (option, leaves) => {
    dispatch(handleStatusChange(leaves, ids, option));
  };

  const handleOpen = (data) => {
    setDatas(data);
    setopenPopup(true);
  };

  const handleOpens = () => {
    setShowPopup(!showPopup);
  };

  const handleCloseEditPopup = () => {
    setShowEditPopup(false);
    setopenPopup(false);
  };

  const handleSaveEdit = () => {
    setShowEditPopup(false);
  };

  return (
    <>
      <div className="w-full h-fit overflow-hidden">
        {openPopup && (
          <MyLeavepopup
            openPopup={openPopup}
            setOpenPopup={setopenPopup}
            datas={datas}
            data-testId="openPopup"
          />
        )}

        {showEditPopup && (
          <EditLeaveDetails
            setOpenPopup={setopenPopup}
            leaveDetails={datas}
            onClose={handleCloseEditPopup}
            onSave={handleSaveEdit}
            handlecancelpopup={handlecancelpopup}
          />
        )}

        {showPopup && (
          <Cancelpopup
            openPopup={showPopup}
            setOpenPopup={setShowPopup}
            datas={datas}
            onClosed={handleOpens}
            onSave={handleSaveEdit}
            setHandlecancelpopup={setHandlecancelpopup}
            popupTitle={popupTitle}
            popupBtn={popupBtn}
            handleOptionClick={handleOptionClick}
          />
        )}
        {!showDetails ? (
          <div>
            <OrganisationLeaveTable
              handleFilterApply={handleFilterApply}
              handleResetFilters={handleResetFilters}
              handleViewDetails={handleViewDetails}
              columns={columns}
              dataFilter={dataFilter}
              dataFilterValue={dataFilterValue}
              searchItem={searchItem}
              searchName={searchName}
              showDetails={showDetails}
              setSearch={setSearch}
              setDepartmentId={setDepartmentId}
              setFilter={setFilter}
              filter={filter}
              checkedDepartment={checkedDepartment}
              checkedDesignation={checkedDesignation}
              handleDepartmentChange={handleDepartmentChange}
              handleDesignationChange={handleDesignationChange}
              localSelectedDepartment={localSelectedDepartment}
              localSelectedDepartmentTitle={localSelectedDepartmentTitle}
              localSelectedDesignation={localSelectedDesignation}
              localSelectedDesignationTitle={localSelectedDesignationTitle}
              addLeavePop={addLeavePop}
              setAddLeavePop={setAddLeavePop}
              setCurrentPage={setCurrentPage}
            />
          </div>
        ) : (
          <div>
            <OrganisationleaveDetails
              setShowDetails={setShowDetails}
              showDetails={showDetails}
              datas={datas}
              setDatas={setDatas}
              handleOpens={handleOpens}
              handleOpen={handleOpen}
              userIdAction={userIdAction}
              setUserIdAction={setUserIdAction}
              openPopup={openPopup}
              columnData={columnData}
              handleOpenDropDown={handleOpenDropDown}
              handleOptionClick={handleOptionClick}
              setSearch={setSearch}
              searchItem={searchItem}
              searchName={searchName}
              setPopupTitle={setPopupTitle}
              setPopupBtn={setPopupBtn}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default OrganisationLeave;

OrganisationLeave.propTypes = {
  activeTab: PropTypes.func,
  setPopupTitle: PropTypes.func,
  columnData: PropTypes.array,
};
