/* eslint-disable no-undef */

import {
  FaReact,
  FaAngular,
  FaBootstrap,
  FaJava,
  FaAppStoreIos,
  FaAndroid,
  FaPython,
  FaFigma,
  FaHtml5,
  FaCss3,
  FaSass,
  FaAirbnb
} from "react-icons/fa";
import { TbBrandReactNative } from "react-icons/tb";
import { DiMongodb, DiMysql, DiPostgresql, DiDjango } from "react-icons/di";
import {
  SiRubyonrails,
  SiTypescript,
  SiTailwindcss,
  SiMui,
  SiAdobeillustrator,
  SiAdobephotoshop,
  SiAdobexd,
  SiMiro,
  SiTestinglibrary
} from "react-icons/si";
import { GoRuby } from "react-icons/go";
import { IoLogoJavascript } from "react-icons/io";
import { GiJesterHat } from "react-icons/gi";
import { MdFlutterDash } from "react-icons/md";
import { FaGolang } from "react-icons/fa6";
import { BsFiletypeScss } from "react-icons/bs";
import { TbSettingsSearch } from "react-icons/tb";
import { PiSealQuestionFill } from "react-icons/pi";
import AppliedJob from "svgComponents/AppliedJob";
import InprogressJob from "svgComponents/InprogressJob";
import SelectedJob from "svgComponents/SelectedJob";
import OnholdJob from "svgComponents/OnholdJob";
import RejectedJob from "svgComponents/RejectedJob";

export const LOCALHOST_URL = "http://localhost:3001/";

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const STAGING_CHAT_URL = process.env.REACT_APP_API_STAGING_CHAT_URL;

export const PRODUCTION_CHAT_URL = process.env.REACT_APP_API_PRODUCTION_CHAT_URL;

export const STAGING_HIRING_URL = process.env.REACT_APP_API_STAGING_HIRING_URL;

export const PRODUCTION_HIRING_URL = process.env.REACT_APP_API_PRODUCTION_HIRING_URL;

export const STAGING_BGV_URL = process.env.REACT_APP_API_STAGING_BGV_URL;

export const PRODUCTION_BGV_URL = process.env.REACT_APP_API_PRODUCTION_BGV_URL;

export const STAGING_PM_TOOL_URL = process.env.REACT_APP_API_STAGING_PM_TOOL_URL;

export const PRODUCTION_PM_TOOL_URL = process.env.REACT_APP_API_PRODUCTION_PM_TOOL_URL;

export const STAGING_FINANCE_URL = process.env.REACT_APP_API_STAGING_FINANCE_URL;

export const PRODUCTION_FINANCE_URL = process.env.REACT_APP_API_PRODUCTION_FINANCE_URL;

export const STAGING_SALES_URL = process.env.REACT_APP_API_STAGING_SALES_URL;

export const PRODUCTION_SALES_URL = process.env.REACT_APP_API_PRODUCTION_SALES_URL;

export const url = "deeporionback-0cd6e44c3c14.herokuapp.com";

export const awsURL = "https://deeporion.s3.ap-south-1.amazonaws.com";

export const projectName = ["Project Name"];

export const adminRoles = ["admin", "supur admin"];

export const monthList = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const paths = [
  "dashboard",
  "timeSheet",
  "attendance",
  "myTeams",
  "leave",
  "calander",
];

export const filterTableDefaultValue = [
  {
    id: 1,
    header: "Employee Name",
    isChecked: true,
    key: "employee_name",
    disable: true,
  },
  {
    id: 2,
    header: "Employee ID",
    isChecked: true,
    key: "employee_id",
    disable: true,
  },
  {
    id: 3,
    header: "Contact No.",
    isChecked: true,
    key: "contact_no",
    disable: true,
  },
  {
    id: 4,
    header: "Designation",
    isChecked: true,
    key: "designation_id",
    disable: true,
  },
  {
    id: 5,
    header: "Official Email",
    isChecked: false,
    key: "official_email",
  },
  {
    id: 6,
    header: "Pincode",
    isChecked: false,
    key: "pincode",
  },
  {
    id: 7,
    header: "City",
    isChecked: false,
    key: "city",
  },
  {
    id: 8,
    header: "LinkedIn Profile",
    isChecked: false,
    key: "linkedin_profile",
  },
  {
    id: 9,
    header: "Blood Group",
    isChecked: false,
    key: "blood_group",
  },
];

export const FilterUsers = [
  {
    id: 0,
    header: "All Employees",
    key: "all",
  },
  {
    id: 1,
    header: "Enable Employees",
    key: "active",
  },
  {
    id: 2,
    header: "Disable Employees",
    key: "disabled",
  },
];

export const filterClients = [
  {
    id: 0,
    header: " All Clients",
    key: "all",
  },
  {
    id: 1,
    header: " Enable  Clients",
    key: "active",
  },
  {
    id: 2,
    header: " Disable Clients",
    key: "disabled",
  },
];

export const SALARY_ARR = [
  "basic_salary",
  "hra",
  "allowance_medical",
  "allowance_special",
  "incentives",
  "bonus_1",
  "bonus_2",
  "pf",
  "gratuity",
  "tds",
  "professional_tax",
  "consultancy_fees",
  "gst",
  "invoice",
];

export const section192 = [
  "id",
  "employeeId",
  "full_name",
  "esic",
  "salary_type",
  "date",
  "total_working_days",
  "Effective_Working_days",
  "total_leaves",
  "status",
];

export const section194 = [
  "employeeId",
  "full_name",
  "salary_type",
  "date",
  "total_working_days",
  "Effective_Working_days",
  "total_leaves",
  "Consultancy Fee",
  "total_salary",
  "email",
  "status",
  "gst",
  "invoice",
  "TDS",
];

export const unprotectedPaths = [
  "/login",
  "/services",
  "/portfolio",
  "/about",
  "/career",
  "/contact-us",
  "/forgot",
  "/checkYourMail",
  "/otp",
  "/reset",
  "/page1",
  "/page2",
  "/page3",
  "/page2-1",
  "/page2-2",
  "/page2-3",
  "/page3-1",
  "/page3-2",
  "/page3-3",
  "/hire-us",
  "/reset/",
];

export const messageInfo = {
  text: {
    sender: {
      _id: 6,
      username: "Tony Stark",
    },
    content: "I am static data",
    chat: {
      _id: 27,
      chatName: "string",
      isGroupChat: true,
      users: [
        {
          _id: 6,
          username: "Tony Stark",
          email: "tony@gmail.com",
        },
        {
          _id: 6,
          username: "Tony Stark",
          email: "tony@gmail.com",
        },
        {
          _id: 7,
          username: "Thor Odhinson",
          email: "thor@gmail.com",
        },
        {
          _id: 8,
          username: "Peter Parker",
          email: "peter@gmail.com",
        },
        {
          _id: 9,
          username: "T'Chala",
          email: "tchala@gmail.com",
        },
        {
          _id: 10,
          username: "Dr banner",
          email: "hulk@gmail.com",
        },
        {
          _id: 12,
          username: null,
          email: "vision@gmail.com",
        },
        {
          _id: 13,
          username: null,
          email: "wanda@gmail.com",
        },
      ],
      createdAt: "2023-11-27T08:52:08.162Z",
      updatedAt: "2023-11-27T08:52:08.162Z",
      latestMessage: 35,
    },
    _id: 35,
    createdAt: "2023-11-30T10:29:06.340Z",
    updatedAt: "2023-11-30T10:29:06.340Z",
  },
};

export const SALARY_TYPE = [
  { id: 1, key: "", value: "Select" },
  { id: 2, key: "full_and_final_payment", value: "Full & Final Payment" },
  { id: 3, key: "Consultancy Fee", value: "Consultancy Fees" },
  { id: 4, key: "Salary", value: "Salary" },
];

export const BONUS_TYPE = [
  { id: 1, key: "", value: "Select" },
  { id: 2, key: "monthly", value: "Monthly" },
  { id: 2, key: "quaterly", value: "Quaterly" },
  { id: 2, key: "halfyearly", value: "Half Yearly" },
  { id: 2, key: "yearly", value: "Yearly" },
];

export const STATUS_ARR = [
  { id: 1, key: "", value: "Select", disable: true },
  { id: 2, key: "done", value: "Done", disable: false },
  { id: 3, key: "pending", value: "Pending", disable: false },
  { id: 4, key: "cancelled", value: "Cancelled", disable: false },
];

export const INVOICE_ARR = [
  { id: 0, key: "all", value: "All" },
  { id: 1, key: "paid", value: "Paid" },
  { id: 2, key: "pending", value: "Pending" },
  { id: 3, key: "cancel", value: "Cancelled" },
];

export const format = new Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR",
  minimumFractionDigits: 2,
});

// add ₹ in salary or amount type
export const AddRsinSalary = (name, obj) => {
  obj = obj?.toString();
  if (SALARY_ARR.includes(name) && obj) {
    obj = !obj?.includes("₹") ? format.format(obj) : obj;
  }

  return obj;
};

export const searchKeys = [
  "email",
  "full_name",
  "employee_id",
  "designation",
  "contact_no",
  "date_of_joining",
  "pincode",
  "city",
  "linkedin_profile",
  "blood_group",
  "emergency_contact_no",
  "employee_type",
  "esic_no",
  "father_name",
  "gender",
  "job_type",
  "manager_id",
  "marital_status",
  "mother_name",
  "uan_no",
  "notice_period",
  "personal_email",
  "state",
  "relieving_date",
  "resignation_date",
  "resignation_status",
  "retention_bonus",
  "retention_bonus_no",
  "retention_time",
  "address",
  "date_of_birth",
];

export const projectFilterTableDefaultValue = [
  {
    id: 0,
    header: "Start Date",
    isChecked: true,
    disable: true,
    key: "startDate",
  },
  {
    id: 1,
    header: "Status",
    disable: true,
    isChecked: true,
    key: "status",
  },
  {
    id: 2,
    header: "Currency",
    isChecked: false,
    key: "currency",
  },
  {
    id: 3,
    header: "Description",
    isChecked: false,
    key: "description",
  },
  {
    id: 4,
    header: "Amount",
    isChecked: false,
    key: "amount",
  },
  {
    id: 5,
    header: "Billing Type",
    isChecked: false,
    key: "billingType",
  },
  {
    id: 6,
    header: "End Date",
    isChecked: false,
    key: "endDate",
  },
];

export const LOCATION_URL =
  // eslint-disable-next-line max-len
  "https://www.google.com/maps/place/DeepOrion+Technology/@26.8471628,75.8019201,16z/data=!4m15!1m8!3m7!1s0x396db7b60434d061:0x8cf67bbea85125b2!2sDeepOrion+Technology!8m2!3d26.8434983!4d75.8024232!10e5!16s%2Fg%2F11rpzdq7tj!3m5!1s0x396db7b60434d061:0x8cf67bbea85125b2!8m2!3d26.8434983!4d75.8024232!16s%2Fg%2F11rpzdq7tj?entry=ttu";

export const creditTimePeriod = [
  {
    id: 1,
    period: "Monthly",
  },
  {
    id: 2,
    period: "Yearly",
  },
];

export const positionArr = ["trainee", "junior", "senior", "lead", "manager"];

export const statusFilterTableDefaultValue = [
  {
    id: 0,
    header: "Total Working Hours",
    isChecked: false,
    disable: false,
    key: "workingHours",
  },
  {
    id: 1,
    header: "Status Date",
    isChecked: false,
    disable: false,
    key: "statusDate",
  },
  {
    id: 2,
    header: "Designation",
    isChecked: true,
    key: "designation",
  },
  {
    id: 3,
    header: "Employee Id",
    isChecked: false,
    key: "employeeId",
  },

  {
    id: 4,
    header: "Billable",
    isChecked: false,
    key: "billable",
  },
];

export const statusFilterTableDefaultValue2 = [
  {
    id: 0,
    header: "Date",
    isChecked: true,
    disable: true,
    key: "statusDate",
  },
  {
    id: 1,
    header: "Total Working Hours",
    isChecked: true,
    disable: true,
    key: "workingHours",
  },
  {
    id: 2,
    header: "Billable",
    isChecked: true,
    key: "billable",
  },
  {
    id: 3,
    header: "Designation",
    isChecked: false,
    key: "designation",
  },
  {
    id: 4,
    header: "Employee Id",
    isChecked: false,
    key: "employeeId",
  },
];

export const notificationData = [
  {
    message: {
      id: 97,
      content: "hi",
      chat_id: 16,
      sender_id: 25,
      receiver_id: 8,
    },
    users: [
      {
        id: 8,
        email: "karan.bairwa@deeporion.com",
        full_name: "Karan Bairwa",
      },
      {
        id: 25,
        email: "admin4@test.com",
        full_name: null,
      },
    ],
  },
  {
    message: {
      id: 97,
      content: "hello",
      chat_id: 16,
      sender_id: 25,
      receiver_id: 8,
    },
    users: [
      {
        id: 8,
        email: "karan.bairwa@deeporion.com",
        full_name: "Karan Bairwa",
      },
      {
        id: 25,
        email: "admin4@test.com",
        full_name: null,
      },
    ],
  },
  {
    message: {
      id: 325,
      content: "what are you doing",
      chat_id: 10,
      sender_id: 45,
      receiver_id: 8,
    },
    users: [
      {
        id: 8,
        email: "karan.bairwa@deeporion.com",
        full_name: "Karan Bairwa",
      },
      {
        id: 45,
        email: "ayushi.khandelwal@deeporion.com",
        full_name: "Ayushi",
      },
    ],
  },
  {
    message: {
      id: 326,
      content: "hi",
      chat_id: 10,
      sender_id: 45,
      receiver_id: 8,
    },
    users: [
      {
        id: 8,
        email: "karan.bairwa@deeporion.com",
        full_name: "Karan Bairwa",
      },
      {
        id: 45,
        email: "ayushi.khandelwal@deeporion.com",
        full_name: "Ayushi",
      },
    ],
  },
];

export const leaveFilterTableDefaultValue = [
  {
    id: 0,
    header: "From Date",
    isChecked: true,
    disable: true,
    key: "fromDate",
  },
  {
    id: 1,
    header: "To Date",
    isChecked: true,
    disable: true,
    key: "toDate",
  },
  {
    id: 2,
    header: "No. Of Days",
    isChecked: true,
    disable: true,
    key: "consumedLeave",
  },
  {
    id: 3,
    header: "Leave Type",
    isChecked: true,
    disable: true,
    key: "leaveType",
  },

  {
    id: 4,
    header: "Reason",
    isChecked: true,
    disable: true,
    key: "reason",
  },

  {
    id: 5,
    header: "Status",
    isChecked: true,
    disable: true,
    key: "status",
  },

  {
    id: 6,
    header: "Action",
    isChecked: true,
    disable: true,
    key: "action",
  },
];

export const HeaderValues = [
  {
    id: 1,
    values: [
      "employeeName",
      "empId",
      "uAN",
      "month",
      "employeeShare",
      "employerShare",
      "status",
    ],
  },
  {
    id: 2,
    values: ["name", "pAN", "totalAmount", "tdsAmount", "status"],
  },
  {
    id: 3,
    values: [
      "assetId",
      "Assetname",
      "assignedTo",
      "assignedBy",
      "assignedDate",
    ],
  },
  {
    id: 4,
    values: ["name", "taxType", "amount", "dueDate", "status"],
  },
  {
    id: 5,
    values: [
      "expenseFor",
      "expenseBy",
      "date",
      "amount",
      "paymentBy",
      "paymentMethod",
    ],
  },
];

export const pfFilterValue = [
  {
    id: 0,
    header: "Employee Name",
    isChecked: false,
    key: "full_name",
  },
  {
    id: 1,
    header: "Employee ID",
    isChecked: false,
    key: "id",
  },
  {
    id: 2,
    header: "UAN Number",
    isChecked: false,
    key: "uan_no",
  },
  {
    id: 3,
    header: "Month",
    isChecked: false,
    key: "month",
  },
  {
    id: 4,
    header: "	Employee Share",
    isChecked: false,
    key: "employee_share",
  },
  {
    id: 5,
    header: "	Employer Share",
    isChecked: false,
    key: "employer_share",
  },
];

export const tabLinks = [
  {
    id: 1,
    value: "providentFund",
  },
  {
    id: 2,
    value: "TDS",
  },
  {
    id: 3,
    value: "assets",
  },
  {
    id: 4,
    value: "companyTax",
  },
  {
    id: 5,
    value: "expenses",
  },
];

export const myTeamsTabLinks = [
  {
    id: 1,
    value: "My Teams",
  },
  {
    id: 2,
    value: "My Project",
  },
  {
    id: 3,
    value: "Organization",
    permissionKey: true,
  },
  {
    id: 4,
    value: "Hierarchy",
  },
];

export const buttonBasedOnTabValue = {
  1: { path: "/management/addProvidentFund", value: "addNew" },
  2: { path: "/management/AddTdsDetails", value: "add" },
  3: { path: "/management/AssetDetails", value: "addAssets" },
  4: { path: "/management/AddCompanyTaxDetails", value: "add" },
  5: { path: "/management/ExpenseDetails", value: "addExpense" },
};

export const filterSalaryValue = [
  {
    header: "CTC",
    isChecked: false,
    key: "ctc",
  },
  {
    header: "Total Working Days",
    isChecked: false,
    key: "total_working_days",
  },
  {
    header: "Effective Working Days",
    isChecked: false,
    key: "Effective_Working_days",
  },
  {
    header: "Total Leaves",
    isChecked: false,
    key: "total_leaves",
  },
  {
    header: "Basic Salary",
    isChecked: false,
    key: "basicSalary",
  },
  {
    header: "House Rent Allowance",
    isChecked: false,
    key: "houseRentAllowance",
  },
  {
    header: "Medical Allowance",
    isChecked: false,
    key: " medicalallowance",
  },
  {
    header: "Special Allowance",
    isChecked: false,
    key: "specialallowance",
  },
  {
    header: "Special Allowance",
    isChecked: false,
    key: "specialallowance",
  },
  {
    header: "Consultancy Fees",
    isChecked: false,
    key: "consultancyfees",
  },
  {
    header: "Incentives",
    isChecked: false,
    key: "incentives",
  },
  {
    header: "Bonus 1",
    isChecked: false,
    key: "bonus1",
  },
  {
    header: "Bonus 2",
    isChecked: false,
    key: "bonus1",
  },
  {
    header: "Bonus Type",
    isChecked: false,
    key: "bonustype",
  },
  {
    header: "Provident Fund",
    isChecked: false,
    key: "providentFund",
  },
  {
    header: "Professional Tax",
    isChecked: false,
    key: "professionaltax",
  },
  {
    header: "Tax Deduction At Source",
    isChecked: false,
    key: "tds",
  },
  {
    header: "Gratuity",
    isChecked: false,
    key: "gratuity",
  },
];

export const writeFeedbackHeadings = [
  "Technical Skills",
  "Soft Skills",
  "Experience and Knowledge",
  "Problem-Solving and Critical Thinking",
  "Project Management",
  "Leadership and Initiative",
  "Cultural Fit",
  "Performance Under Pressure",
];

export const skills = [
  { id: 1, name: "React JS", icon: FaReact, className: "text-blue-500" },
  {
    id: 2,
    name: "React Native",
    icon: TbBrandReactNative,
    className: "text-blue-500",
  },
  { id: 3, name: "MongoDB", icon: DiMongodb, className: "text-green-500" },
  { id: 4, name: "Mysql", icon: DiMysql, className: "text-blue-700" },
  { id: 5, name: "Postgresql", icon: DiPostgresql, className: "text-blue-800" },
  { id: 6, name: "Angular", icon: FaAngular, className: "text-red-600" },
  {
    id: 7,
    name: "Ruby on Rails",
    icon: SiRubyonrails,
    className: "text-red-700",
  },
  { id: 8, name: "Ruby", icon: GoRuby, className: "text-red-700" },
  { id: 9, name: "HTML", icon: FaHtml5, className: "text-orange-500" },
  { id: 10, name: "Css", icon: FaCss3, className: "text-blue-500" },
  {
    id: 11,
    name: "Typescript",
    icon: SiTypescript,
    className: "text-blue-600",
  },
  {
    id: 12,
    name: "Javascript",
    icon: IoLogoJavascript,
    className: "text-yellow-500",
  },
  { id: 13, name: "Tailwind", icon: SiTailwindcss, className: "text-teal-500" },
  { id: 14, name: "SCSS", icon: BsFiletypeScss, className: "text-pink-600" },
  { id: 15, name: "SASS", icon: FaSass, className: "text-pink-700" },
  { id: 16, name: "MUI", icon: SiMui, className: "text-blue-600" },
  {
    id: 17,
    name: "Bootstrap",
    icon: FaBootstrap,
    className: "text-indigo-600",
  },
  { id: 18, name: "Jest", icon: GiJesterHat, className: "text-pink-700" },
  { id: 19, name: "Enzyme", icon: FaAirbnb, className: "#8C1515" },
  { id: 20, name: "Java", icon: FaJava, className: "text-blue-700" },
  { id: 21, name: "IOS", icon: FaAppStoreIos, className: "text-black" },
  { id: 22, name: "Android", icon: FaAndroid, className: "text-green-500" },
  { id: 23, name: "Flutter", icon: MdFlutterDash, className: "text-blue-900" },
  { id: 24, name: "SEO", icon: TbSettingsSearch, className: "text-blue-500" },
  {
    id: 25,
    name: "Pen Testing",
    icon: SiTestinglibrary,
    className: "text-red-800",
  },
  { id: 26, name: "GoLang", icon: FaGolang, className: "text-blue-900" },
  { id: 27, name: "Python", icon: FaPython, className: "text-blue-900" },
  { id: 28, name: "Django", icon: DiDjango, className: "text-green-900" },
  { id: 29, name: "Figma", icon: FaFigma, className: "text-red-600" },
  {
    id: 30,
    name: "Adobe Illustrator",
    icon: SiAdobeillustrator,
    className: "text-yellow-500",
  },
  {
    id: 31,
    name: "Adobe Photoshop",
    icon: SiAdobephotoshop,
    className: "text-blue-500",
  },
  { id: 32, name: "Adobe XD", icon: SiAdobexd, className: "text-purple-700" },
  { id: 33, name: "Miro", icon: SiMiro, className: "text-black" },
  {
    id: 34,
    name: "Others",
    icon: PiSealQuestionFill,
    className: "text-[#333]",
  },
];

export const section = {
  section194: "section 194",
  section192: "section 192",
};

export const message = [
  {
    chatId: 12,
    content: "Expedita.",
    createdAt: "2024-03-05T11:01:05.086+05:30",
    id: 231,
    senderId: 26,
    senderName: "karan bairwa",
    files: [
      "https://i.pinimg.com/564x/b6/2a/7c/b62a7cef004ca9078abe50c72fb8c915.jpg",
    ],
    users: [
      {
        email: "karan2@deeporion.com",
        full_name: "karan 2",
        id: 47,
      },
      {
        email: "karan@deeporion.com",
        full_name: "karan bairwa",
        id: 26,
      },
    ],
  },
  {
    chatId: 12,
    content: "",
    createdAt: "2024-03-05T11:01:05.086+05:30",
    id: 234,
    senderId: 26,
    senderName: "karan bairwa",
    files: [
      "https://i.pinimg.com/564x/b6/2a/7c/b62a7cef004ca9078abe50c72fb8c915.jpg",
    ],
    users: [
      {
        email: "karan2@deeporion.com",
        full_name: "karan 2",
        id: 47,
      },
      {
        email: "karan@deeporion.com",
        full_name: "karan bairwa",
        id: 26,
      },
    ],
  },
  {
    chatId: 12,
    content: "MY ANME IS KARAN BAIRWA",
    createdAt: "2024-03-05T11:01:05.086+05:30",
    id: 232,
    senderId: 47,
    senderName: "karan bairwa",
    users: [
      {
        email: "karan2@deeporion.com",
        full_name: "karan 2",
        id: 47,
      },
      {
        email: "karan@deeporion.com",
        full_name: "karan bairwa",
        id: 26,
      },
    ],
  },
];

export const JobApplicantsHeader = [
  {
    id: 1,
    icon: AppliedJob,
    headName: "Applied",
    status: "applied",
  },
  {
    id: 2,
    icon: InprogressJob,
    status: "inprogress",
    headName: "Inprogress",
  },
  {
    id: 3,
    icon: SelectedJob,
    status: "selected",
    headName: "Selected",
  },
  {
    id: 4,
    icon: OnholdJob,
    status: "onhold",
    headName: "Onhold",
  },
  {
    id: 5,
    icon: RejectedJob,
    status: "rejected",
    headName: "Rejected",
  },
];

export const leaveTypeOption = [
  {
    id: 0,
    header: "select leave type",
    key: "Select Leave Type",
  },
  {
    id: 1,
    header: "paid Leave",
    key: "paid",
  },
  {
    id: 2,
    header: "string leave",
    key: "string",
  },
  {
    id: 3,
    header: "bereavement leave",
    key: "bereavement",
  },
  {
    id: 4,
    header: "paternity leave",
    key: "paternity",
  },
  {
    id: 5,
    header: "marriage leave",
    key: "marriage",
  },
  {
    id: 6,
    header: "compensatory leave",
    key: "compensatory",
  },
  {
    id: 7,
    header: "maternity leave",
    key: "maternity",
  },
  {
    id: 8,
    header: "sick leave",
    key: "sick",
  },
  {
    id: 9,
    header: "privilege leave",
    key: "privilege",
  },
  {
    id: 10,
    header: "casual leave",
    key: "casual",
  },
];

export const CompanyDetail = {
  companyName: "",
  companyContactNo: [
    {id: 1, valuee: ""},
  ],
  registration: [
    {id: 1, valuee: ""},
  ],
  panNo: [
    { id: 1, valuee: ""},
  ],
  tanNo: [
    {id: 1, valuee: ""},
  ],
  companyMailInput: [
    {id: 1, valuee: ""},
    {id: 2, valuee: ""},
  ],
  emailIdNewsLetter: "",
  faceBook: "",
  linkedIn: "",
  instagram: "",
  X: "",
  branchInput: [
    {
      id: "",
      isHeadquarter: "",
      address: "",
      country: null,
      state: null,
      city: null,
      pin: "",
      phone: [
        {id: 1, valuee: ""},
      ],
      email: [
        {id: 1, valuee: ""},
        {id: 2, valuee: ""},
      ],
      registrationDate: "",
      gstNumber: "",
      imagesUrl: [],
    },
  ],
  projects: "",
  clients: "",
  experience: "",
};

export const NewBranchInput = {
  id: "",
  isHeadquarter: "",
  address: "",
  country: null,
  state: null,
  city: null,
  pin: "",
  phone: [
    {id: 1, valuee: ""},
  ],
  email: [
    {id: 1, valuee: ""},
    {id: 2, valuee: ""},
  ],
  registrationDate: "",
  gstNumber: "",
  imagesUrl: [],
};
