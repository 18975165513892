export const formatLeaveTypeResponse = (response) => {
  const requiredResponse = [];
  response?.map((obj) => {
    requiredResponse.push({
      id: obj?.id,
      leaveName: obj?.leave_name,
      isActive: obj?.is_active,
    });
  });

  return requiredResponse;
};

export const formatAddLeaveRequest = (values) => {
  return {
    leave: {
      id_of_user: values?.id,
      employee_name: values?.employeeName,
      from_date: values?.fromDate,
      to_date: values?.toDate,
      consumed_leave: values?.numOfDays,
      last_action_by: values?.lastactionby,
      leave_type: values?.leaveType,
      reason: values?.reason,
      leave_status: values?.leaveStatus,
      from_time: values?.fromShift,
      to_time: values?.toShift,
    },
  };
};

export const formattedStatusChange = (leaves, option) => {

  return {

    from_time: leaves?.fromTime,
    to_time: leaves?.toTime,
    from_date: leaves?.fromDate,
    to_date: leaves?.toDate,
    leave_type: leaves?.leaveType,
    leave_status: option,
    reason: leaves?.reason,
    consumed_leave: 1,
    employee_name: leaves?.fullName,

  };
};

export const formatEmployeeData = (response) => {

  const requiredResponse = [];
  response?.map((obj) => {
    requiredResponse.push({
      id: obj.leaves?.id,
      fullName: obj.user.full_name,
      email: obj.user?.email,
      appliedDate: obj.leaves?.applied_date ?? null,
      fromDate: obj.leaves?.from_date,
      toDate: obj.leaves?.to_date,
      leaveType: obj.leaves?.leave_type?.leave_name || null,
      reason: obj.leaves?.reason,
      toTime: obj.leaves?.to_time,
      leaveStatus: obj.leaves?.leave_status,
      fromTime: obj.leaves?.from_time,
      consumedLeave: obj.leaves.consumed_leave || null,
      imagesUrl: obj.leaves.images_url || null,
      reason_for_cancel: obj?.leaves?.reason_for_cancel,
      reason_for_reject: obj?.leaves?.reason_for_reject,
    });
  });

  return requiredResponse;
};

export const formatMyLeaveData = (response) => {
  const requiredResponse = response?.map((obj) => ({
    id: obj?.leaves?.id || null,
    appliedDate: obj.leaves?.applied_date ?? null,
    name: obj?.leaves?.employee_name || 'No Data',
    fromDate: obj?.leaves?.from_date || 'No Data',
    toDate: obj?.leaves?.to_date || 'No Data',
    noOfdays: obj?.leaves?.no_of_leaves || "No Data",
    leaveType: obj?.leaves?.leave_type?.leave_name || 'N/A',
    reason: obj?.leaves?.reason || 'No reason provided',
    consumedLeave: obj?.leaves?.consumed_leave,
    lastActionby: obj?.leaves?.last_action_by,
    leaveStatus: obj?.leaves?.leave_status || 'Unknown',
    userId: obj?.leaves?.user_id,
  })) || [];

  return requiredResponse;
};

export const formatLeaveSearchData = (response) => {
  const requiredResponse = response?.flatMap((leaves) => {
    if (leaves?.leaves?.length > 0) {
      return leaves.leaves.map((obj) => ({
        id: leaves.user?.id || null,
        fullName: leaves.user?.full_name || 'N/A',
        email: leaves.user?.email || 'N/A',
        fromDate: obj?.from_date || null,
        toDate: obj?.to_date || null,
        leaveType: obj?.leave_type?.leave_name || 'N/A',
        reason: obj?.reason || 'No reason provided',
        toTime: obj?.to_time || null,
        leaveStatus: obj?.leave_status || 'Unknown',
        fromTime: obj?.from_time || null,
        consumedLeave: obj?.consumed_leave || null,
        lastActionby: obj?.last_action_by,
      }));
    }

    return [];
  }) || [];

  return requiredResponse;
};

export const formatTeamLeaveDataAdmin = (response) => {
  const requiredResponse = [];

  response?.data?.map((obj) => {
    requiredResponse.push({
      id: obj.leaves.id,
      fromDate: obj.leaves.from_date,
      toDate: obj.leaves.to_date,
      leaveType: obj.leaves.leave_type?.leave_name || null,
      reason: obj.leaves.reason,
      toTime: obj.leaves.to_time,
      leaveBalance: obj.leaves.leave_balance,
      leaveStatus: obj.leaves.leave_status,
      fromTime: obj.leaves.from_time,
      consumedLeave: obj?.leaves?.consumed_leave,
      lastActionby: obj?.leaves?.last_action_by,
      name: obj?.user?.full_name,
      designation: obj?.user?.designation,
      department: obj?.user?.department,
      userId: obj?.user?.id,
    });
  });

  return {
    data: requiredResponse,
    pageCount: response?.total_pages,
  };

};

export const formatTeamLeaveData = (response) => {

  const requiredResponse = [];

  response?.map((employee) => {
    requiredResponse.push({
      id: employee?.id,
      fullName: employee?.full_name,
      email: employee?.email,
      logInTime: employee?.log_in_time,
      profilePictureUrl: employee?.profile_picture_url,
      dateOfJoining: employee?.date_of_joining,
      jobType: employee?.job_type,
      employeeType: employee?.employee_type,
      workFromOffice: employee?.work_from_office,
      noticePeriod: employee?.notice_period,
      sectionApplicable: employee?.section_applicable,
      ctc: employee?.ctc,
      esicNo: employee?.esic_no,
      panNo: employee?.pan_no,
      uanNo: employee?.uan_no,
      createdAt: employee?.created_at,
      updatedAt: employee?.updated_at,
    });
  });

  return requiredResponse;
};

export const LeaveData = (response) => {
  const requiredResponse = [];

  response.leaves?.map((item) => (
    requiredResponse.push({
      id: item?.id,
      applydate: item?.created_at,
      leaveType: item?.leave_type?.leave_name,
      fromDate: item?.from_date,
      toDate: item?.to_date,
      noofdays: item?.no_of_leaves,
      reason: item?.reason,
      leaveStatus: item?.leave_status,
      consumedleave: item?.consumed_leave,
      lastActionby: item?.last_action_by,
      employee_name: item?.employee_name,
      employeeid: item?.user_id,
      from_time: item?.from_time,
      to_time: item?.to_time,
    })
  ));

  return requiredResponse;
};
